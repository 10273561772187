<template>
  <div id="facilities">
    <div class="facilities">
      <div class="blur"><div></div></div>
      <TitlePage
        class="home-title"
        :showButton="false"
        :header="header"
        :description="description"
      />
    </div>
    <DetailsTemplate
      v-for="service in services"
      :key="service.id"
      :id="service.id"
      :CheckupHeader="service.CheckupHeader"
      :CheckupDescription="service.CheckupDescription"
      :image="service.checkupImage"
    />
    <router-link to="/contact"
      ><button class="about-btn">Make An Appointment</button></router-link
    >
    <Footer />
  </div>
</template>

<script>
import TitlePage from "../components/layouts/TitlePage";
import DetailsTemplate from "../components/layouts/DetailsTemplate";
import Footer from "../components/Footer";

export default {
  name: "Facilities",
  components: {
    TitlePage,
    DetailsTemplate,
    Footer,
  },
  data() {
    return {
      header: "Hospital Facilities",
      description:
        "At Royal Hospitals, our facilities are designed to the highest degree of patient care and support. We are one of the most comprehensive health center in the region, and our facilities are truly state-of-the-art. Learn more about our Hospital facility today, and do not hesitate to reach out if you have a question or concern.",

      services: [
        {
          id: 0,
          CheckupHeader: "Consultation Rooms",
          CheckupDescription:
            "Consultation Rooms at Royal Hospitals designed to handle different kinds of a medical consultation with respect to the available Departments, these include the Gynecology and Obstetrics Department, the Pediatrics Department, the Cardiology Department, and Telemedicine.",
          checkupImage: require("@/assets/facilitiesImages/0499e6_a20e9ed0537b4d8cb889380f3f5c81bd_mv2.webp"),
        },
        {
          id: 1,
          CheckupHeader: "Wards",
          CheckupDescription:
            "Our wards are designed to be comfortable for patients. They are equipped with all the necessary facilities for our patient's well-being including comfortable beds, lavatories, entertainment systems, and more. Available Wards include the Labour Ward, Postnatal Ward, Antenatal Ward, Male Ward, and the Pediatric Ward",
          checkupImage: require("@/assets/facilitiesImages/0499e6_c569ebe8e65d4bb08f993c982f52afa0_mv2.webp"),
        },
        {
          id: 2,
          CheckupHeader: "Theatre",
          CheckupDescription:
            "Our medical facilities combine state-of-the-art technology with patient-centered design. Our theatre is equipped to handle everything from Cesarean delivery, and other procedures with regards to Obstetrics, Gynecology, and Pediatrics.",
          checkupImage: require("@/assets/facilitiesImages/0499e6_6beb477088e046069d13b5588ad21757_mv2.webp"),
        },
        {
          id: 3,
          CheckupHeader: "Delivery Room",
          CheckupDescription:
            "Our delivery rooms are made to create a comfortable atmosphere for the expecting mother, to aid her in the Delivery Process. They come with the Delivery bed, baby warmers, scales, and an entertainment system to help ease the process of delivery.",
          checkupImage: require("@/assets/facilitiesImages/0499e6_5377158f450a48baa42bc10040530a26_mv2.webp"),
        },
        {
          id: 4,
          CheckupHeader: "Pharmacy",
          CheckupDescription:
            "We have dedicated pharmaceutical facilities for both wholesale and retail of pharmaceutical products. We are open 24 Hours to make it convenient for everyone to visit at any time of need.",
          checkupImage: require("@/assets/facilitiesImages/0499e6_53a2b50f961b404e8f23c0f896c4a780_mv2.webp"),
        },
        {
          id: 5,
          CheckupHeader: "Laboratory",
          CheckupDescription:
            "Our state-of-the-art laboratories are designed for optimal use in all necessary medical laboratory services. The laboratory is equipped with industry-standard equipment such as a Urine analyzer, a Hormone analyzer, a Chemical analyzer and more.",
          checkupImage: require("@/assets/facilitiesImages/0499e6_35c9d98cc15142cf9c3594f291d4ca60_mv2.webp"),
        },
        {
          id: 6,
          CheckupHeader: "Waiting Areas",
          CheckupDescription:
            "Our Waiting Areas offer a warm environment that fosters innovation, strives for excellence, nurtures respect for others, and ensures accountability in delivering world-class care to our patients. Care to learn more about our Waiting Areas? Get in touch with us today to hear more about what we can do for you and your family.",
          checkupImage: require("@/assets/facilitiesImages/0499e6_1fa0928fdb4b4390aaa925b58e968095_mv2.webp"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.about-btn {
  /* width: 100px; */
  height: 40px;
  background-color: #ce515c;
  border: unset;
  color: #fff;
}
button {
  margin-top: 2%;
  transition: 200ms;
  transition-timing-function: ease-in-out;
}
button:hover {
  opacity: 0.9;
}

.blur {
  /* z-index: -2; */
  position: absolute;
  background-image: url("../assets/servicesImages/0499e6_92ab1e7fe7cb45b285758e218ba11ae0_mv2.webp");
  background-position: center;
  background-size: cover;
  filter: blur(0px);
  -webkit-filter: blur(4px);
  width: 100%;
  height: 100%;
}
.blur div {
  background-color: rgba(59, 77, 177, 0.5);
}
.facilities {
  contain: content;
}
.home-title {
  position: relative;
  height: 70vh;
  background-color: rgba(59, 77, 177, 0.5);
  display: grid;
  place-items: center;
}
.CheckupSections {
  display: flex;
  background-color: salmon;
}
.colums {
  width: 100%;
  background-color: seagreen;
}
@media screen and (max-width: 400px){
  .home-title {
  display: grid;
  place-items: unset;
}
}
</style>